import { LegacyEvents } from '~/enums/tracking/tracking-events.enums';

export default defineNuxtRouteMiddleware(() => {
  const { user } = useUserStore();

  if (!user.value) {
    return;
  }

  const data = {
    event: LegacyEvents.MmGa4GetUserData,
    user: {
      login_status: 'logged_in',
      registration_status: 'registered',
      first_name: user.value.firstName,
      last_name: user.value.lastName,
      email: user.value.email,
      user_country: user.value.country,
      user_profession: user.value.profession,
      user_professional_track: user.value.professionalTrack,
      plan_type: user.value.planType,
      blocked_from_mailing: user.value.blockedFromMailing,
    },
  } as { event: string; user: Record<string, string | boolean | number>; user_id?: string };

  if (user.value.traceId) {
    data.user_id = user.value.traceId;
  }

  useNuxtApp().$gtmPush(data);
});
