type FunctionToHandle<T> = (...args: any[]) => Promise<T>;

export default async function useErrorHandling<T> (fn: FunctionToHandle<T>, errorMessage?: string): Promise<T | undefined> {
  try {
    const result = await fn();
    return result;
  } catch (_error) {
    const error = _error as {
      statusCode?: number;
      statusMessage?: string;
      message?: string;
      cause?: string;
      response?: any;
    };

    if (!error) {
      showError(createError({
        message: 'An error occurred.',
        fatal: true,
      }));

      return;
    }

    let statusMessage = 'Internal Server Error';

    if (error.statusMessage) {
      statusMessage = error.statusMessage;
    }

    if (!error.statusMessage && !error.response) {
      statusMessage = 'Response is empty, please check your network connection.';
    }

    showError(createError({
      message: errorMessage,
      cause: error.cause || error.message || 'Unknown error',
      fatal: true,
      statusCode: error?.statusCode ?? 500,
      statusMessage,
    }));
  }
}
