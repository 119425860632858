export enum ActivityEvents {
  LessonStarted = 'lesson_started',
  LessonCompleted = 'lesson_completed',
  LessonFeedbackGiven = 'feedback_to_lesson_given',
  QuizStarted = 'quiz_started',
  QuizCompleted = 'quiz_completed',
  KickoffQuizStarted = 'kickoff_quiz_started',
  KickoffQuizCompleted = 'kickoff_quiz_completed',
  MasteryQuizStarted = 'mastery_quiz_started',
  MasteryQuizCompleted = 'mastery_quiz_completed',
}

export enum CourseEvents {
  CourseStarted = 'course_started',
  CourseCompleted = 'course_completed',
  CourseBookmarked = 'course_bookmarked',
  RecommendedCourseClicked = 'recommended_course_clicked',
  ResourceDownloaded = 'download_file',
}

export enum GenericEvents {
  MenuClick = 'menu_click',
}

export enum LegacyEvents {
  MmGa4ResourceDownloaded = 'mm_ga4_resource_download',
  MmGa4GetUserData = 'mm_ga4_get_user_data',
}

export enum SubscriptionEvents {
  SubscriptionPurchased = 'subscription_purchased',
  SubscriptionCancelled = 'subscription_cancelled',
  SubscriptionReactivated = 'subscription_reactivated',
  SubscriptionUpgraded = 'subscription_upgraded',
}

export enum PageEvents {
  DiscoveryPageViewed = 'discovery_page_viewed',
  CourseLandingPageViewed = 'course_landing_page_viewed',
}

export enum CertificateEvents {
  CertificateClaimed = 'certificate_claimed',
  CertificateDownloaded = 'certificate_downloaded',
  CertificateAvailableForDownload = 'certificate_available_for_download',
}

export enum UserEvents {
  SpecialtyPromptShared = 'specialty_prompt_shared',
}