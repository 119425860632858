import { default as professional_45infoMjbDvhZWSvMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/professional-info.vue?macro=true";
import { default as profile_45infobGXMBCNZZKMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/profile-info.vue?macro=true";
import { default as subscriptionu6BGPIqNbPMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/subscription.vue?macro=true";
import { default as advanced_45searchdpaVCWFN8AMeta } from "/opt/atlassian/pipelines/agent/build/pages/alpha/advanced-search.vue?macro=true";
import { default as lesson_45searchfh8zG3nhnlMeta } from "/opt/atlassian/pipelines/agent/build/pages/alpha/lesson-search.vue?macro=true";
import { default as about_45youJM3z5bDF1KMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/about-you.vue?macro=true";
import { default as forgot_45passwordNKRCiBhxIdMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/forgot-password.vue?macro=true";
import { default as login103yOntRZlMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/login.vue?macro=true";
import { default as logoutqIAm7RUQ0AMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/logout.vue?macro=true";
import { default as reset_45passwordOmvMP187YjMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/reset-password.vue?macro=true";
import { default as signupmpIopPFtlAMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/signup.vue?macro=true";
import { default as verify_45emailZmh0dQ0x1VMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/verify-email.vue?macro=true";
import { default as indexYmU1bHOs5uMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/comparison/index.vue?macro=true";
import { default as _91lessonId_93JrMFO0Va84Meta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/lessons/[lessonId].vue?macro=true";
import { default as _91quizId_93irpicLxwCrMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/quizzes/[quizId].vue?macro=true";
import { default as _91courseId_93FQDpCNkBTwMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId].vue?macro=true";
import { default as indexvwLW6cOZ6kMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/index.vue?macro=true";
import { default as dashboardaGDr6YjHlOMeta } from "/opt/atlassian/pipelines/agent/build/pages/dashboard.vue?macro=true";
import { default as _91planGroup_93DSLUUe1ydzMeta } from "/opt/atlassian/pipelines/agent/build/pages/enroll/[planGroup].vue?macro=true";
import { default as thank_45you0JUjaIXH86Meta } from "/opt/atlassian/pipelines/agent/build/pages/enroll/thank-you.vue?macro=true";
import { default as acceptTKWDsb6WcUMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/[groupId]/invite/accept.vue?macro=true";
import { default as indexf1v6euLwGxMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/index.vue?macro=true";
import { default as _91memberId_93Ts2YmnoXOlMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/members/[memberId].vue?macro=true";
import { default as pricingevsz0GYkgYMeta } from "/opt/atlassian/pipelines/agent/build/pages/pricing.vue?macro=true";
import { default as ai_45prompting_45tooldJiq69lQ6XMeta } from "/opt/atlassian/pipelines/agent/build/pages/tools/ai-prompting-tool.vue?macro=true";
import { default as _91webinar_93UGPsQNVSzFMeta } from "/opt/atlassian/pipelines/agent/build/pages/webinars/[webinar].vue?macro=true";
import { default as indexCOFIk3Py82Meta } from "/opt/atlassian/pipelines/agent/build/pages/webinars/index.vue?macro=true";
export default [
  {
    name: "account-professional-info",
    path: "/account/professional-info",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/professional-info.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-info",
    path: "/account/profile-info",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/profile-info.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "alpha-advanced-search",
    path: "/alpha/advanced-search",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/alpha/advanced-search.vue").then(m => m.default || m)
  },
  {
    name: "alpha-lesson-search",
    path: "/alpha/lesson-search",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/alpha/lesson-search.vue").then(m => m.default || m)
  },
  {
    name: "auth-about-you",
    path: "/auth/about-you",
    meta: about_45youJM3z5bDF1KMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/about-you.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordNKRCiBhxIdMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login103yOntRZlMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordOmvMP187YjMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupmpIopPFtlAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    meta: verify_45emailZmh0dQ0x1VMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "course",
    path: "/courses/:courseId()",
    meta: _91courseId_93FQDpCNkBTwMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId].vue").then(m => m.default || m),
    children: [
  {
    name: "comparison",
    path: "comparison",
    meta: indexYmU1bHOs5uMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson",
    path: "lessons/:lessonId()",
    meta: _91lessonId_93JrMFO0Va84Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/lessons/[lessonId].vue").then(m => m.default || m)
  },
  {
    name: "quiz",
    path: "quizzes/:quizId()",
    meta: _91quizId_93irpicLxwCrMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/quizzes/[quizId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardaGDr6YjHlOMeta || {},
    alias: ["/"],
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "enroll",
    path: "/enroll/:planGroup()",
    meta: _91planGroup_93DSLUUe1ydzMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/enroll/[planGroup].vue").then(m => m.default || m)
  },
  {
    name: "thank-you",
    path: "/enroll/thank-you",
    meta: thank_45you0JUjaIXH86Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/enroll/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "groups-groupId-invite-accept",
    path: "/groups/:groupId()/invite/accept",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/[groupId]/invite/accept.vue").then(m => m.default || m)
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "groups-members-memberId",
    path: "/groups/members/:memberId()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/members/[memberId].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingevsz0GYkgYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "tools-ai-prompting-tool",
    path: "/tools/ai-prompting-tool",
    meta: ai_45prompting_45tooldJiq69lQ6XMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/tools/ai-prompting-tool.vue").then(m => m.default || m)
  },
  {
    name: "webinars-webinar",
    path: "/webinars/:webinar()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webinars/[webinar].vue").then(m => m.default || m)
  },
  {
    name: "webinars",
    path: "/webinars",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webinars/index.vue").then(m => m.default || m)
  }
]