import { defineNuxtPlugin } from 'nuxt/app';

declare global {
  interface Window {
    fwSettings: any;
    FreshworksWidget: any;
  }
}

export default defineNuxtPlugin(() => {
  // Add freshdesk globals
  window.fwSettings = {
    'widget_id':13000000638,
  };

  // eslint-disable-next-line
  !function(){if("function"!=typeof window.FreshworksWidget){var n:any=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}

  // Add freshdesk script to head
  const script = document.createElement('script');
  script.setAttribute('src', 'https://widget.freshworks.com/widgets/13000000638.js');
  script.setAttribute('async', 'true');
  script.setAttribute('type', 'text/javascript');
  document.head.appendChild(script);

  const { currentRoute } = useRouter();

  watch(currentRoute, () => {
    prefillWidget();
  });

  const execMediaQueryChanges = (matches: boolean) => {
    if (!window.FreshworksWidget) {
      setTimeout(() => execMediaQueryChanges(matches), 200);
      return;
    }

    if (matches) {
      window.FreshworksWidget('show', 'launcher');
    } else {
      window.FreshworksWidget('hide', 'launcher');
    }
  };

  const match = window.matchMedia('(min-width: 1200px)');

  if (match.addEventListener) {
    match.addEventListener('change', e => execMediaQueryChanges(e.matches));
    // bugfix for Safari
  } else if (match.addListener) {
    match.addListener(e => execMediaQueryChanges(e.matches));
  }

  execMediaQueryChanges(match.matches);

  const prefillWidget = () => {
    if (currentRoute.value.path === '/oauth/callback') {
      return;
    }

    if (typeof window === 'undefined') {
      return;
    }

    if (!window.FreshworksWidget) {
      setTimeout(prefillWidget, 200);
      return;
    }

    const { user , isLoaded } = useUserStore();

    // wait until the user is loaded to fill widget
    if (!isLoaded.value) {
      setTimeout(prefillWidget, 200);
      return;
    }

    window.FreshworksWidget('identify', 'ticketForm', {
      name: user.value?.firstName + ' ' + user.value?.lastName,
      email: user.value?.email,
      description: '',
      custom_fields: {
        cf_url_link: window.location.href,
      },
    }, {
      formId: 13000000625, // Ticket Form ID
    });
  };

  const openFreshdeskWidgetForm = () => {
    if (!window.FreshworksWidget) {
      return;
    }

    window.FreshworksWidget('open');
  };

  return {
    provide: {
      openFreshdeskWidgetForm,
    },
  };
});