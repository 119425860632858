import type { useToast } from "primevue/usetoast";

/**
 * A composable that provides a workaround for PrimeVue's useToast() in other composables.
 *
 * The default useToast() cannot be used directly inside composables due to scope limitations,
 * leading to errors like "PrimeVue Toast Not Found". This helper ensures access to the global
 * PrimeVue toast instance by retrieving it from Nuxt's global properties.
 */
export const usePVToastService = () => {
  const nuxtApp = useNuxtApp();
  const getToast: typeof useToast = () => nuxtApp.vueApp.config.globalProperties.$toast;
  return getToast();
};