import {
  SubscriptionEvents as BackendSubscriptionEvents,
  CertificateEvents as BackendCertificateEvents,
  CourseEvents as BackendCourseEvents,
} from '~/enums/backend-events.enums';
import {
  SubscriptionEvents as TrackingSubscriptionEvents,
  CertificateEvents as TrackingCertificateEvents,
  CourseEvents as TrackingCourseEvents,
} from '~/enums/tracking/tracking-events.enums';

export default defineNuxtPlugin({
  dependsOn: ['echo', 'gtm'],
  setup: () => {

    const { $gtmPush, $privateUserChannel } = useNuxtApp();

    const sendSubscriptionEvent = (backendEvent: string, eventData: any) => {
      const eventMap = {
        [BackendSubscriptionEvents.SubscriptionPurchased]: TrackingSubscriptionEvents.SubscriptionPurchased,
        [BackendSubscriptionEvents.SubscriptionCancelled]: TrackingSubscriptionEvents.SubscriptionCancelled,
        [BackendSubscriptionEvents.SubscriptionReactivated]: TrackingSubscriptionEvents.SubscriptionReactivated,
        [BackendSubscriptionEvents.SubscriptionUpgraded]: TrackingSubscriptionEvents.SubscriptionUpgraded,
      } as { [key: string]: string };

      if (!(backendEvent in eventMap)) {
        console.warn(`Could not resolve backend event name: ${backendEvent}`, eventData);
        return;
      }

      const gtmEventBody = {
        event: eventMap[backendEvent],
        plan_type: eventData.plan.type,
        plan_price: eventData.plan.price,
        currency: eventData.plan.currency,
        subscription_date: eventData.subscription.term_start,
      } as any;

      if (backendEvent === BackendSubscriptionEvents.SubscriptionCancelled) {
        gtmEventBody['old_plan_type'] = eventData.previous_plan.type;
      }

      $gtmPush(gtmEventBody);
    };

    const sendCertificateEvent = (backendEvent: BackendCertificateEvents, data: any) => {
      const eventMap = {
        [BackendCertificateEvents.CertificateDownloadedBasic]: TrackingCertificateEvents.CertificateDownloaded,
        [BackendCertificateEvents.CertificateDownloadedPro]: TrackingCertificateEvents.CertificateDownloaded,
        [BackendCertificateEvents.CertificateClaimed]: TrackingCertificateEvents.CertificateClaimed,
        [BackendCertificateEvents.CertificateAvailableForDownload]: TrackingCertificateEvents.CertificateAvailableForDownload,
      } as { [key: string]: string };

      if (!(backendEvent in eventMap)) {
        console.warn(`Could not resolve backend event name: ${backendEvent}`, data);
        return;
      }

      const gtmEventBody = {
        'event': eventMap[backendEvent],
        'course_name': data.course_name,
        'course_id': data.course_id,
      } as any;

      if (backendEvent === BackendCertificateEvents.CertificateClaimed || backendEvent === BackendCertificateEvents.CertificateDownloadedPro || backendEvent === BackendCertificateEvents.CertificateDownloadedBasic) {
        gtmEventBody['accreditation_body'] = data.accreditation_body;
        gtmEventBody['claim_date'] = data.claim_date;
      }

      if (backendEvent === BackendCertificateEvents.CertificateClaimed || backendEvent === BackendCertificateEvents.CertificateDownloadedPro) {
        gtmEventBody['cme_credits'] = data.cme_credits;
      }

      $gtmPush(gtmEventBody);
    };

    const sendCourseEvents = (backendEvent: BackendCourseEvents, data: any) => {
      const eventMap = {
        [BackendCourseEvents.CourseStarted]: TrackingCourseEvents.CourseStarted,
        [BackendCourseEvents.CourseCompleted]: TrackingCourseEvents.CourseCompleted,
      } as { [key: string]: string };

      if (!(backendEvent in eventMap)) {
        console.warn(`Could not resolve backend event name: ${backendEvent}`, data);
        return;
      }

      const gtmEventBody = {
        'event': eventMap[backendEvent],
        'course_name': data.course.title,
        'course_id': data.course.id,
        'content_type': data.course.type,
        'teachers': data.course.teachers,
        'lessons_count': data.course.lessons,
        'quizzes_count': data.course.quizzes,
        'credits_count': data.course.cme_credits,
        'duration': data.course.duration,
      } as any;

      $gtmPush(gtmEventBody);
    };

    const startListener = () => {
      if (!$privateUserChannel.value) {
        return;
      }

      const channel = $privateUserChannel.value;

      channel.listen(BackendSubscriptionEvents.SubscriptionPurchased, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionPurchased, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionCancelled, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionCancelled, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionReactivated, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionReactivated, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionUpgraded, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionUpgraded, event);
      });

      channel.listen(BackendCertificateEvents.CertificateDownloadedBasic, (event: any) => {
        sendCertificateEvent(BackendCertificateEvents.CertificateDownloadedBasic, event);
      });

      channel.listen(BackendCertificateEvents.CertificateDownloadedPro, (event: any) => {
        sendCertificateEvent(BackendCertificateEvents.CertificateDownloadedPro, event);
      });

      channel.listen(BackendCertificateEvents.CertificateClaimed, (event: any) => {
        sendCertificateEvent(BackendCertificateEvents.CertificateClaimed, event);
      });

      channel.listen(BackendCertificateEvents.CertificateAvailableForDownload, (event: any) => {
        sendCertificateEvent(BackendCertificateEvents.CertificateAvailableForDownload, event);
      });

      channel.listen(BackendCourseEvents.CourseStarted, (event: any) => {
        sendCourseEvents(BackendCourseEvents.CourseStarted, event);
      });

      channel.listen(BackendCourseEvents.CourseCompleted, (event: any) => {
        sendCourseEvents(BackendCourseEvents.CourseCompleted, event);
      });

    };

    watch($privateUserChannel, () => {
      startListener();
    }, { immediate: true });
  },
});