export enum SubscriptionEvents {
  SubscriptionPurchased = 'Subscription\\SubscriptionPurchased',
  SubscriptionCancelled = 'Subscription\\SubscriptionCancelled',
  SubscriptionReactivated = 'Subscription\\SubscriptionReactivated',
  SubscriptionUpgraded = 'Subscription\\SubscriptionUpgraded',
}

export enum CertificateEvents {
  CertificateAvailableForDownload = 'Certificate\\CertificateAvailableForDownload',
  CertificateClaimed = 'Certificate\\CertificateClaimed',
  CertificateDownloadedPro = 'Certificate\\CertificateDownloadedPro',
  CertificateDownloadedBasic = 'Certificate\\CertificateDownloadedBasic',
}

export enum CourseEvents {
  CourseStarted = 'Course\\CourseStartedEvent',
  CourseCompleted = 'Course\\CourseCompletedEvent',
}