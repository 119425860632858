import { NOTIFICATION_TYPE } from "~/composables/useNotifications";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { checkAuthenticationStatus } = useAuthentication();
  const { getUnreadNotifications, markAsRead } = useNotifications();

  // Exclude routes that do not require authentication
  if (
    to.path.includes('/invite/accept') ||
      [
        '/auth/login',
        '/auth/signup',
        '/auth/signup-prompt',
        '/auth/reset-password',
        '/auth/forgot-password',
        '/auth/verify-email',
        '/user/login',
        '/user/register',
        '/user/password',
      ].includes(to.path)
  ) {
    return;
  }

  const isAuthenticated = await checkAuthenticationStatus();

  if (!isAuthenticated) {
    const next = to.path === '/' ? '' : `?next=${encodeURIComponent(to.fullPath)}`;
    const loginUrl = '/auth/login';

    return navigateTo(`${loginUrl}${next}`, {
      replace: true,
    });
  }

  const { user } = useUserStore();

  if (!user?.value?.isVerified) {
    return navigateTo('/auth/verify-email', {
      replace: true,
    });
  }

  if (!user?.value?.registrationCompleted && to.path !== '/auth/about-you') {
    return navigateTo('/auth/about-you', {
      replace: true,
    });
  }

  if (user?.value?.numberOfUnreadNotifications && (to.path === '/dashboard' || to.path === '/error/thank-you')) {
    const notifications = await getUnreadNotifications();
    if (notifications?.length) {
      const groupInvitationAcceptedUnreadNotification = notifications.find(notification =>
        notification.data.notificationName === NOTIFICATION_TYPE.B2B_INVITATION_ACCEPTED &&
        !notification.readAt,
      );

      if (groupInvitationAcceptedUnreadNotification) {
        await markAsRead(groupInvitationAcceptedUnreadNotification.id);
        if (user?.value) {
          user.value.numberOfUnreadNotifications--;
        }

        // new user case (set password -> login -> dashboard -> thank-you page)
        if (to.path === '/dashboard' && from.path !== '/enroll/thank-you') {
          return navigateTo('/enroll/thank-you', {
            replace: true,
          });
        }
      }
    }
  }
});