import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(app => {
  if (app.$config.public.env !== 'production') {
    console.warn('Sentry is only enabled in production environment.');
    return;
  }

  Sentry.init({
    app: app.vueApp,
    dsn: "https://1690e342220e79fcb66c94b98d68a601@o4507412248395776.ingest.de.sentry.io/4507452801482832",
    environment: app.$config.public.env,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.25,
    tracePropagationTargets: ["app.medmastery.com/api", "alpha.medmastery.com/api"],
  });
});