export const useSystemMessages = () => {
  const dispatchMessage = (
    summary: string,
    severity: "error" | "success" | "info" | "warn" | undefined = 'info',
    detail?: string | string[],
    life: number | undefined = 5000,
  ) => {
    const toastService = usePVToastService();

    toastService.add({
      severity,
      summary,
      detail,
      life,
    });
  };

  return {
    dispatchMessage,
  };
};

export default useSystemMessages;