import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as iconsSolid from './fontawesome/icons-solid';
import * as iconsRegular from './fontawesome/icons-regular';
import * as iconsLight from './fontawesome/icons-light';

config.autoAddCss = false;

library.add({ ...iconsSolid, ...iconsRegular, ...iconsLight });

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});