import { defineNuxtPlugin } from 'nuxt/app';

declare global {
  interface Window { dataLayer: any[] }
}

export default defineNuxtPlugin({
  name: 'gtm',
  setup: () => {
    window.dataLayer = window.dataLayer || [];

    const gtmPush = (msg: any) => {
      window.dataLayer.push(msg);
    };

    const page_view = () => {
      gtmPush({
        event: 'page_view',
      });
    };

    const ai_prompt_user_selected_role = (selectedRoleName: string) => {
      gtmPush({
        event: 'ai_prompt_user_selected_role',
        selectedRoleName,
      });
    };

    const ai_prompt_user_copied_prompt = (selectedRoleName: string) => {
      gtmPush({
        event: 'ai_prompt_user_copied_prompt',
        selectedRoleName,
      });
    };

    const ai_prompt_user_started_over = (selectedRoleName: string) => {
      gtmPush({
        event: 'ai_prompt_user_started_over',
        selectedRoleName,
      });
    };

    const ai_prompt_user_opened_additional_settings = (selectedRoleName: string) => {
      gtmPush({
        event: 'ai_prompt_user_opened_additional_settings',
        selectedRoleName,
      });
    };

    return {
      provide: {
        gtmPush,

        /**
         * @deprecated (this is triggered automatically by GTM itself)
         */
        page_view,

        ai_prompt_user_selected_role,
        ai_prompt_user_copied_prompt,
        ai_prompt_user_started_over,
        ai_prompt_user_opened_additional_settings,
      },
    };
  },
});