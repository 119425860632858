import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authentication_45global from "/opt/atlassian/pipelines/agent/build/middleware/01.authentication.global.ts";
import _02_45redirects_45global from "/opt/atlassian/pipelines/agent/build/middleware/02.redirects.global.ts";
import _03_45analytics_45global from "/opt/atlassian/pipelines/agent/build/middleware/03.analytics.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authentication_45global,
  _02_45redirects_45global,
  _03_45analytics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "course-activity-access-middleware": () => import("/opt/atlassian/pipelines/agent/build/middleware/courseActivityAccessMiddleware.ts"),
  "course-middleware": () => import("/opt/atlassian/pipelines/agent/build/middleware/courseMiddleware.ts")
}