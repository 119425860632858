import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto',
      touch: true,
      allowHTML: true,
      theme: 'medmastery',
    },
  });
});