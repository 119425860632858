import sessionHelper from '~/utils/sessionHelper';
import type { FetchError } from 'ofetch';

export const useAuthenticatedFetch = async <DataT>(url: string | (() => string), options?: any) => {
  const headers = new Headers(options?.headers as HeadersInit);

  const response = await useFetch<DataT>(url, {
    retry: false,
    ...options,
    headers,
    onRequest: sessionHelper.fetchOnRequestInterceptor,
  });

  if (response.error.value) {
    const err = response.error.value as FetchError;

    if (err.statusCode && [401, 419].includes(err.statusCode)) {
      if (err.statusCode === 419) {
        await sessionHelper.fetchCsrf();
      }
      await useAuthentication().logout();
    }

    throw err;
  }

  return response;
};

export async function useAuthenticatedFetchSimplified <DataT = unknown> (url: string) {
  const response: DataT = await $fetch(url, {
    method: 'GET',
    onRequest: sessionHelper.fetchOnRequestInterceptor,
  });

  return response;
}