<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface applicationError {
  statusCode?: number;
  message?: string;
  description?: string;
  [key: string]: any;
}

const props = defineProps<{ error: applicationError }>();

const errorTitles = {
  400: 'Bad request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Page not found',
  500: 'Internal server error',
};

const showTechnicalInfo = ref(true);

const pageTitle = computed(() => {
  const statusCode = props.error.statusCode;
  if (typeof statusCode === 'number') {
    return errorTitles[statusCode as keyof typeof errorTitles] || 'Error';
  }

  return 'Error';
});

const { copy, copied, isSupported } = useClipboard();

const getErrorText = () => {
  let text = '';
  text += 'Status code: ' + props.error.statusCode + '; \n';
  text += 'Message: ' + props.error.message + '; \n';
  text += 'Description: ' + props.error.description + '; \n';
  text += 'Status message: ' + props.error.statusMessage + '; \n';
  text += 'Error cause: ' + props.error.cause + ';';
  return text;
};

useHead({
  title: `${pageTitle.value}`,
});
</script>

<template>
  <div class="application-error">
    <NuxtLayout name="default">
      <div class="page page--error">
        <div class="pt-12 pb-24">
          <div class="flex flex-col items-center">
            <img
              src="@images/illustrations/empty-state-nora.png"
              alt="Not found"
            >
            <h2>Sorry, the page you were looking for is not available.</h2>
            <p>
              Why not take a look at the links below to browse through our course
              contents or read an article.
            </p>
          </div>

          <div class="flex flex-col gap-2 ">
            <NuxtLink to="/dashboard">
              <Card class="shadow-none">
                <template #content>
                  <div class="flex items-center gap-4">
                    <FontAwesomeIcon icon="fa-house" />
                    <div class=" f-16-regular">
                      Back to the
                      <NuxtLink to="/dashboard">
                        Home Page
                      </NuxtLink>
                    </div>
                  </div>
                </template>
              </Card>
            </NuxtLink>

            <NuxtLink to="/courses">
              <Card class="shadow-none">
                <template #content>
                  <div class="flex items-center gap-4">
                    <FontAwesomeIcon icon="fa-book" />
                    <div class="f-16-regular">
                      Browse the
                      <NuxtLink to="/courses">
                        Video Course library
                      </NuxtLink>
                    </div>
                  </div>
                </template>
              </Card>
            </NuxtLink>
          </div>

          <div class="mt-9 mb-4">
            <div class="flex items-center justify-between w-100 f-18-bold gap-2">
              <div @click.prevent="showTechnicalInfo = !showTechnicalInfo">
                <a href="#show-technical">
                  <FontAwesomeIcon :icon="showTechnicalInfo ? 'fa-chevron-up' : 'fa-chevron-down'" />
                  Technical details
                </a>
              </div>

              <Button
                v-if="isSupported"
                text
                :severity="!copied ? 'secondary' : 'success'"
                class="p-3"
                @click="copy(getErrorText())"
              >
                <FontAwesomeIcon
                  v-if="!copied"
                  icon="fa-copy"
                />
                <FontAwesomeIcon
                  v-else
                  icon="fa-check"
                />
              </Button>
            </div>
          </div>

          <div
            v-if="showTechnicalInfo"
            class="flex flex-col gap-2"
          >
            <div>
              <strong>Status code:</strong> {{error.statusCode}}
            </div>
            <div>
              <strong>Error message:</strong> {{error.message}}
            </div>

            <div v-if="error.description">
              {{error.description}}
            </div>

            <Card
              v-if="error.statusMessage || error.cause"
              class="shadow-none mt-2 overflow-x"
            >
              <template #content>
                <div class="flex flex-col gap-2">
                  <b v-if="error.statusMessage">
                    {{error.statusMessage}}
                  </b>

                  <code class="block text-wrap break-all">{{error.cause}}</code>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss">
@import "@mui/assets/styles/font-styles.scss";
@import "@mui/assets/styles/utility.scss";

.page--error {
    @include container-sizer('content-narrow');

    img {
      max-width: 100%;
    }

    .p-card {
      border: 1px solid var(--palette-neutral-30);

    .p-card-content {
      padding: 0 !important;

      .m-fa-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
</style>
