import type { LocationQueryRaw } from 'vue-router';

export default defineNuxtRouteMiddleware(async to => {
  const doRedirect = async (path: string, query?: LocationQueryRaw) => {
    clearError();
    return navigateTo({ path, query }, { redirectCode: 301 });
  };

  if (to.path === '/') {
    return doRedirect('/dashboard', to.query);
  }

  const redirects = {
    '/course-library': '/courses',
    '/enroll': '/enroll/basic-monthly',
    '/enroll/pro': '/enroll/pro-yearly',
    '/user/login': '/auth/login',
    '/user/register': '/auth/signup',
    '/user/password': '/auth/forgot-password',
  };

  if (Object.keys(redirects).includes(to.path)) {
    const redirectPath = redirects[to.path as keyof typeof redirects];
    return doRedirect(redirectPath, to.query);
  }

  // If no page is found, check if there is a redirect
  if (to.matched.length === 0) {
    const path = to.path;
    const url = useApiUrl('/redirects?source=' + encodeURIComponent(path));

    try {
      const redirectResponse = await useAuthenticatedFetchSimplified<{destination: string}>(url);

      if (redirectResponse.destination) {
        return doRedirect(redirectResponse.destination, to.query);
      }
    } catch (e) {
      console.error('error fetching redirects', e);
      showError({ statusCode: 404, statusMessage: 'Page not found' });
    }
  }
});
