import type { Channel } from 'laravel-echo';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import * as Sentry from '@sentry/vue';

declare global {
  interface Window {
    Pusher: any;
  }
}

export default defineNuxtPlugin({
  name: 'echo',
  setup: nuxtApp => {
    window.Pusher = Pusher;

    const { user } = useUserStore();

    const userCount = ref<number>(0);
    const echo = ref<Echo | null>(null);
    const privateUserChannel = ref<Channel | null>(null);

    const updateUserCount = (delta: number, set: boolean = false) => {
      if (set) {
        userCount.value = delta;
      } else {
        userCount.value += delta;
      }

      console.log('members-active', userCount.value);
    };

    const initialize = () => {
      if (echo.value) {
        echo.value.disconnect();
        echo.value = null;
      }

      echo.value = new Echo({
        broadcaster: 'reverb',
        key: nuxtApp.$config.public.reverb.key,
        forceTLS: true,
        wsHost: nuxtApp.$config.public.reverb.host,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: '/api/broadcasting/auth',
      });

      echo.value.connector.pusher.connection.bind('unavailable', () => {
        console.warn('Websocket connection unavailable.');
      });

      echo.value.connector.pusher.connection.bind('failed', () => {
        Sentry.captureException(new Error('Websocket connection failed.'));
      });

      echo.value.connector.pusher.connection.bind('error', (error: any) => {
        console.warn('Websocket error:', error);
      });

      if (user.value) {
        privateUserChannel.value = echo.value.private('user.' + user.value.id);
      }

      connectToPresenceChannel();
    };

    const disconnect = () => {
      if (!echo.value) {
        return;
      }

      echo.value.leaveAllChannels();
      echo.value.disconnect();
      echo.value = null;
      privateUserChannel.value = null;
    };

    const connectToPresenceChannel = () => {
      echo.value?.join('medmastery')
        .here((users: any[]) => updateUserCount(users.length, true))
        .joining(() => updateUserCount(1))
        .leaving(() => updateUserCount(-1));
    };

    watch(user, async u => {
      if (u) {
        initialize();
      } else {
        disconnect();
      }
    }, { immediate: true });

    return {
      provide: {
        echo,
        privateUserChannel,
      },
    };

  },
});